
import { mapActions } from 'vuex';

export default {
  name: 'base-header-search',
  data() {
    return {
      term: null,
      host: '',
    };
  },
  methods: {
    ...mapActions('sulu', ['searchWebsite']),
    search(value) {
      this.searchWebsite(value).then(res => {
        this.items = res;
      });
    },
    selectItem(item) {
      this.$router.push(item.url);
      this.$nextTick(() => {
        this.$refs.autocomplete.clean();
      });
    },
  },
};
