
export default {
  name: 'base-ornament-bg',
  props: {
    whiteBg: {
      type: Boolean,
      default: false,
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    grayBg: {
      type: Boolean,
      default: false,
    },
    lightBg: {
      type: Boolean,
      default: false,
    },
    half: {
      type: Boolean,
      default: false,
    },
  },
};
