
export default {
  name: 'icon-ornament',
  props: {
    whiteBg: {
      type: Boolean,
      default: false,
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    lightBg: {
      type: Boolean,
      default: false,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
  },
};
