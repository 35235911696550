import { render, staticRenderFns } from "./autocomplete.vue?vue&type=template&id=7d88aba7&"
import script from "./autocomplete.vue?vue&type=script&lang=js&"
export * from "./autocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconX: require('/opt/yamal.ru/node_modules/razlet-ui/src/icons/icon-x/index.vue').default,RzIconCross: require('/opt/yamal.ru/node_modules/razlet-ui/src/icons/icon-cross/index.vue').default,RzIconDropArrow: require('/opt/yamal.ru/node_modules/razlet-ui/src/icons/icon-drop-arrow/index.vue').default,RzDropdown: require('/opt/yamal.ru/node_modules/razlet-ui/src/components/dropdown/index.vue').default})
