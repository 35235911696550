
import {CancelToken} from 'axios';
import {oldDebounce as debounce} from 'razlet-sdk/lib/utils/helpers';
import Sender from 'razlet-sdk/lib/api/sender';

export default {
  name: 'main-autocomplete',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    transitionName: {
      type: String,
      default: 'fade',
    },
    errorText: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    pattern: {
      type: String,
      default: '',
    },
    big: {
      type: Boolean,
      default: false,
    },
    isError: Boolean,
    required: Boolean,
    multiple: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      isOpen: false,
      calledRequest: null,
      cancelToken: null,
      items: [],
      selectedItems: [],
      selectedItem: null,
      sender: null,
      term: '',
      typeAheadPointer: -1,
      randomId: null,
      isFocused: false,
    };
  },
  computed: {
    isFilled() {
      return this.term || typeof this.term === 'number' || this.selectedItems.length || this.selectedItem;
    },
    lastOptionIndex() {
      return this.items.length - 1;
    },
    reversedOptions() {
      return [...this.items].reverse();
    },
    classes() {
      return {
        'main-autocomplete--focused': this.isFocused,
        'main-autocomplete--filled': this.isFilled,
        'main-autocomplete--error': this.isError,
        'main-autocomplete--disabled': this.disabled,
        'main-autocomplete--big': this.big,
      };
    },
  },
  watch: {
    value: {
      handler(value) {
        this.fill(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.randomId = String(Math.random());
    this.sender = new Sender({baseUrl: `/${this.$locale}`});
    this.cancelToken = CancelToken;
    this.getData().then(() => {
      this.fill(this.value);
    });
    this.search = debounce(this.getData, 700);
  },
  methods: {
    fill(value) {
      if (this.multiple) {
        if (value && value.length) this.fillSelectedItems(value);
        else this.selectedItems = [];
      } else if (value) this.fillSelectedItem(value);
      else this.selectedItem = null;
    },
    fillSelectedItems(value) {
      const selected = [];
      value.forEach(item => {
        selected.push(this.findItem(item));
      });
      this.selectedItems = selected;
    },
    fillSelectedItem(value) {
      this.selectedItem = this.findItem(value);
      this.term = this.selectedItem.name;
      this.getData('');
    },
    findItem(id) {
      return this.items.find(item => item.id === id);
    },
    removeItem(index) {
      const selected = [...this.selectedItems];
      selected.splice(index, 1);
      this.change(selected.map(item => item.id));
    },
    change(value) {
      this.$emit('change', value);
    },
    fillInput(val) {
      this.term = val;
      this.search(this.term);
      if (!this.multiple) this.change(null);
    },
    getData(term) {
      if (typeof term === 'undefined') return Promise.reject();
      if (this.calledRequest) this.calledRequest.cancel('Отменен запрос поиска');
      this.calledRequest = this.cancelToken.source();
      return this.
        sender.get(`${this.path}${term}`, { cancelToken: this.calledRequest.token })
        .then(res => {
          this.calledRequest = null;
          const list = res.data?._embedded?.hits
            ? res.data._embedded.hits.map((item, i) => {
              return {
                id: i,
                name: item.document.title,
                url: item.document.url,
                image: item.document.imageUrl ? item.document.imageUrl.replace('{format}', '100х100') : '',
                type: this.formatType(item.document.properties._structure_type),
              };
            })
            : [];
          this.items = list;
        });
    },
    formatType(value) {
      return this.$t(`search.types.${value}`);
    },
    hideDropdown() {
      this.isOpen = false;
    },
    selectItem(item) {
      this.$emit('select', item);
      if (this.multiple) {
        this.term = '';
        const value = this.value || [];
        this.change([...value, item.id]);
      } else {
        this.term = item.name;
        this.change(item.id);
      }
      this.getData('');
      this.isOpen = false;
    },
    focusin() {
      if (this.isMobile) {
        setTimeout(() => {
          window.scrollTo({top: this.$el.offsetTop - 70});
        }, 100);
      }
      this.$emit('focusin');
    },
    inputFocus() {
      this.isFocused = true;
    },
    focusout() {
      this.isFocused = false;
      this.hideDropdown();
      this.$emit('focusout');
    },
    typeAheadUp() {
      if(!this.disabled){
        if (!this.isOpen) {
          this.isOpen = true;
        }
        if (this.typeAheadPointer > 0) {
          const nextPointer = this.typeAheadPointer - 1;
          const option = this.items[nextPointer];
          const isDisabled = option ? option[this.disabledProp] || false : false;
          if (!isDisabled) {
            this.typeAheadPointer -= 1;
          } else {
            this.typeAheadPointer -= 1;
            this.typeAheadUp();
          }
        } else {
          const nextEnabledOption = this.reversedOptions.findIndex(
            o => o[this.disabledProp] !== true
          );
          this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
        }
      }
    },
    typeAheadDown() {
      if(!this.disabled){
        if (!this.isOpen) {
          this.isOpen = true;
        }
        if (this.typeAheadPointer < this.lastOptionIndex) {
          const nextPointer = this.typeAheadPointer + 1;
          const option = this.items[nextPointer];
          const isDisabled = option ? option[this.disabledProp] || false : false;
          if (!isDisabled) {
            this.typeAheadPointer += 1;
          } else {
            this.typeAheadPointer += 1;
            this.typeAheadDown();
          }
        } else {
          this.typeAheadPointer = this.items.findIndex(
            o => o[this.disabledProp] !== true
          );
        }
      }
    },
    typeAheadSelect() {
      if (this.items[this.typeAheadPointer] && !this.isPickedOption(this.items[this.typeAheadPointer].id)) {
        this.selectItem(this.items[this.typeAheadPointer]);
      }
      this.$emit('enter');
    },
    isSelectedOption(option, index) {
      return this.typeAheadPointer === index;
    },
    isPickedOption(id) {
      if (this.multiple) return this.selectedItems.map(item => item.id).includes(id);
      return this.selectedItem === id;
    },
    clean() {
      this.change(null);
      this.term = '';
      this.items.length = 0;
      this.$emit('clean');
    },
  },
};
