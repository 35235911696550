
import {mapActions} from 'vuex';

export default {
  name: 'base-header-lang-switcher',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'slide-top',
    },
  },
  computed: {
    locales() {
      return this.$i18n.availableLocales;
    },
  },
  methods: {
    ...mapActions('sulu', ['initSuluSender']),
    setLocale(value) {
      this.$locale = value;
      this.$cookiz.set('defaultLanguage', value, {path: '/', maxAge: 60 * 60 * 24 * 365, ...(!process.env.suluURL && { domain: '.yamal.ru', sameSite: 'lax' })});
      this.initSuluSender({requests: ['getMenu']});
      this.$bus.$emit('locale-changed');
    },
  },
};
