
export default {
  name: 'base-header-drawer-block',
  props: {
    group: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  watch: {
    expanded: {
      handler(value) {
        this.isExpanded = value;
      },
      immediate: true,
    },
  },
};
