
import {mapGetters} from 'vuex';

export default {
  name: 'base-header-drawer',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeBlockIndex: null,
      isSearch: false,
    };
  },
  computed: {
    ...mapGetters('sulu', ['menu']),
    firstMenu () {
      return this.isMobile ? this.menu : this.menu.filter((item, index) => index < 2);
    },
    secondMenu () {
      return this.menu.filter((item, index) => index >= 2);
    },
    importantButtons () {
      return [
        {
          name: 'tablo',
          icon: 'icon-tablo',
          color: '#1BA1ED',
          colorHover: '#FFFFFF',
          bg: 'transparent',
          bgHover: 'transparent',
          href: '/tablo',
          fill: '#1BA1ED',
        },
      ];
    },
  },
  watch: {
    isOpen: {
      handler() {
        this.countActiveIndex();
      },
      deep: true,
    },
  },
  created() {
    this.countActiveIndex();
  },
  methods: {
    countActiveIndex() {
      if (!this.menu || !this.menu.length) this.activeBlockIndex = null;
      let idx = null;
      this.menu.forEach((head, index) => {
        head.items.forEach(subItem => {
          if (subItem.link === this.$route.path) {
            idx = index;
          }
        });
      });
      this.activeBlockIndex = idx;
    },
    expandBlock(e, index) {
      this.activeBlockIndex = this.activeBlockIndex === index ? null : index;
    },
    openSearch() {
      this.isSearch = true;
      const fakeInput = document.createElement('input');
      fakeInput.setAttribute('type', 'text');
      fakeInput.style.position = 'fixed';
      fakeInput.style.opacity = 0.1001;
      fakeInput.style.height = 0;
      fakeInput.style.fontSize = '16px'; // disable auto zoom

      // you may need to append to another element depending on the browser's auto 
      // zoom/scroll behavior
      document.body.prepend(fakeInput);

      // focus so that subsequent async focus will work
      fakeInput.focus();

      this.$forceNextTick(() => {
        // now we can focus on the target input
        this.$refs.search.$refs.autocomplete.$refs.input.focus();

        // cleanup
        fakeInput.remove();
      });
      /* this.$forceNextTick(() => {
        this.$refs.search.$refs.autocomplete.$refs.input.click();
        this.$refs.search.$refs.autocomplete.$refs.input.focus();
      }); */
    },
    hideSearch() {
      this.isSearch = false;
    },
  },
};
